import React from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import Layout from "../components/Layout/Post"
import Seo from '../components/seo'
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import {
  Link,
  Box,
  Container,
  Heading,
  Flex,
  Text,
  Divider,
} from "@chakra-ui/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import PostCards from "../components/Home/PostCards"
import GetWeld from '../components/Common/GetWeld'

const Post = ({ data: { post }, pageContext }) => {
  console.log({ pageContext })
  const { title, date, categories, cover } = post.frontmatter

  const coverImageData = getImage(cover)

  const similarPosts = pageContext.similarPosts.map(post => post.node)

  return (
    <Layout >
      <Seo title={title}/>
      <Container maxW={"container.lg"} mt={{ base: "44px", md: "93px" }}>
        <Heading fontSize={{ base: "24px", md: "48px" }}>{title}</Heading>
        <Flex mt="16px" mb="16px" flexDirection={{ base: "column", md: "row" }}>
          <Flex alignItems="center">
            <Text color="#626262" fontSize={{ base: "12px", md: "16px" }}>
              {date}
            </Text>
            <Divider
              color="#c5c5c3"
              orientation="vertical"
              h="16px"
              mx="10px"
              display={{ base: "none", md: "initial" }}
            />
          </Flex>
          <Flex my={{ base: "10px", md: "0px" }}>
            {categories.map((category, index) => {
              return (
                <Flex alignItems="center">
                  <Link
                    as={GatsbyLink}
                    to={`/category/${category.toLowerCase()}`}
                    activeClassName="category_link_active"
                    textTransform="uppercase"
                    color="brandGreen"
                    fontSize={{ base: "10px", md: "14px" }}
                  >
                    {category}
                  </Link>
                  {index + 1 !== categories.length && (
                    <Divider
                      orientation="vertical"
                      mx="10px"
                      h="21px"
                      display={{ base: "none", md: "initial" }}
                    />
                  )}
                </Flex>
              )
            })}
          </Flex>
        </Flex>
        <Box mb={{ base: "24px", md: "50px" }}>
          <GatsbyImage image={coverImageData} alt={title} />
        </Box>
        <MDXRenderer>{post.body}</MDXRenderer>
        <Box mb={{ base: "40px", md: "120px" }}></Box>
      </Container>
      <Box bg="#F7F7F4">
        <Container maxW={"container.xl"}>
          <Flex
            flexDirection="column"
            py={{ base: "40px", md: "60px" }}
            justifyContent="center"
          >
            <Heading fontSize={{ base: "30px", md: "48px" }} textAlign="center">
              Similar Posts
            </Heading>
            <PostCards posts={similarPosts} />
            <Flex justifyContent="center">
              {" "}
              <Link
                as={GatsbyLink}
                to="/"
                fontWeight="medium"
                fontSize="14px"
                color="black"
                bg="transparent"
                border="1px solid"
                _hover={{}}
                px="32px"
                py="19px"
                borderRadius="3px"
              >
                See all blog posts
              </Link>
            </Flex>
          </Flex>
          <GetWeld/>
        </Container>
      </Box>
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    post: mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        slug
        categories
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 992
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
