import React from "react"
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Link,
  Text,
  Image,
  chakra,
  Code,
  Tr,
  Td,
  Th
} from "@chakra-ui/react"

const h1 = props => (
  <Heading fontSize={{ base: "20px", md: "36px" }}>{props.children}</Heading>
)

const h2 = props => (
  <Heading
    mt={{ base: "16px", md: "24px" }}
    fontSize={{ base: "18px", md: "32px" }}
  >
    {props.children}
  </Heading>
)

const a = props => (
  <Link
    textDecoration="underline !important"
    fontSize={{ base: "16px", md: "20px" }}
    {...props}
  >
    {props.children}
  </Link>
)

const blockquote = props => (
  <Box
    bg="#EFEEEA"
    px={{ base: "16px", md: "40px" }}
    py={{ base: "32px", md: "40px" }}
    position="relative"
  >
    <Image
      display={{ base: "none", lg: "block" }}
      src="/images/blockquote.svg"
      position="absolute"
      right="-30px"
      top="30px"
    />
    <Image
      display={{ base: "block", lg: "none" }}
      src="/images/blockquote.svg"
      position="absolute"
      right="-10px"
      top="16px"
      w="37px"
    />
    <Heading>{props.children}</Heading>
  </Box>
)

const p = props => (
  <Text
    fontSize={{ base: "16px", md: "20px" }}
    my={{ base: "16px", md: "24px" }}
  >
    {props.children}
  </Text>
)

const ul = props => (
  <chakra.ul ml="30px" mt="-10px" fontSize={{ base: "16px", md: "20px" }}>
    {props.children}
  </chakra.ul>
)

const inlineCode = props => <Code color="brandGreen">{props.children}</Code>

const table = props => {
  return <Box overflowX="scroll">
<Table variant="simple" size="md" overflow="scroll">{props.children}</Table>

  </Box>
}

const thead = props => {
  return <Thead>{props.children}</Thead>
}

const tbody = props => {
  return <Tbody>{props.children}</Tbody>
}


const tr = props => {
  return <Tr>{props.children}</Tr>
}

const td = props => {
  return <Td borderColor="#E9E9E9">{props.children}</Td>
}

const th = props => {
  return <Th borderColor="#A1A1A1" fontSize="16px" color="#A1A1A1">{props.children}</Th>
}

export const components = {
  h1,
  h2,
  a,
  p,
  blockquote,
  ul,
  inlineCode,
  table,
  thead,
  tbody,
  tr,
  td,
  th
}
