import * as React from "react"
import { Box } from "@chakra-ui/react"
import { useStaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { components } from "../Blog/MDXComponents"

import Header from "./header"
import Footer from "./footer"

import "./global.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryPost {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MDXProvider components={components}>
      <Box
        bg="white"
        background={{
          base:
            "linear-gradient(to top, #fff, #fff 50%, #fff 93%, #F7F7F4 75%)",
          md: "linear-gradient(to top, #fff, #fff 50%, #fff 87%, #F7F7F4 75%)",
        }}
        className="blogColor"
      >
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        {children}
        <Footer/>
      </Box>
    </MDXProvider>
  )
}

export default Layout
